body {
  font: 14pt "Lucida Grande", Helvetica, Arial, sans-serif;
}

.content-area {
  padding: 25px;
}

.row {
  --bs-gutter-x: 0.5rem;
}

.content {
  border-left: 1px solid silver;
  border-right: 1px solid silver;
  background: linear-gradient(to top, #ffffffee, #ffffff99), url('../../src/images/crumpled-rice-paper.jpg') repeat top center;
  overflow-y: scroll;
  overflow-scrolling: auto;
  scroll-behavior: auto;
  word-wrap: break-word;
}

.frame {
  border: 1px solid silver;
  flex-grow: 1;
  background: linear-gradient(to top, #3333ff66, #3333ffdd), url('../../src/images/27302.jpg') repeat top center;
  background-size: 250px;
}

#footer {
  min-height: 60px;
  text-align: center;
  padding: 15px;
}

.i-text, .ui-text {
  font-style: italic;
}

.ui-text, .u-text {
  text-decoration: underline;
}

ul.a {
  list-style-type: circle;
}

.subtitles {
  font-size: medium;
}

.li-large {
  margin: 20px 0;
}


.m-span {
  font-weight: bolder;
  font-size: small;
}

.col-r {
  text-align: center;
  padding: 2px;
  border: 2px solid #515151;
  vertical-align: center;
  white-space: break-spaces;
}

.attributions {
  font-size: 7pt;
}

.navbar-brand {
}

.nav-link {
  white-space: nowrap;
}

#header {
  padding: 8px;
}